import rwd from '@js/utils/rwd.js'

const resizeObserver = {
    data() {
        return {
            isMobile:
                typeof window !== 'undefined'
                    ? !window.matchMedia(rwd.mq.mdUp)?.matches
                    : false,
            isDesktop:
                typeof window !== 'undefined'
                    ? window?.matchMedia(rwd.mq.mdUp)?.matches
                    : true
        }
    },
    mounted() {
        if (typeof window !== 'undefined') {
            this.matchMediaQueries()
            window.addEventListener('resize', this.handleRwd)
        }
    },

    methods: {
        handleRwd() {
            clearTimeout(this.resizeTimer)
            this.resizeTimer = setTimeout(() => this.matchMediaQueries(), 20)
        },
        matchMediaQueries() {
            this.isMobile = !window.matchMedia(rwd.mq.mdUp).matches
            this.isDesktop = window.matchMedia(rwd.mq.mdUp).matches
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleRwd)
    }
}

export default resizeObserver
