var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-similar-product-wrapper" },
    [
      _c("Modal", {
        ref: "modal",
        on: { afterLeave: _vm.destroySlider },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function() {
              return [
                _c("div", { staticClass: "similar-product" }, [
                  _c("h2", { staticClass: "similar-product__title" }, [
                    _vm._v(
                      "\n                    Do Twojego zamówienia możesz dodać\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "similar-product__slider" }, [
                    _c(
                      "div",
                      { ref: "items" },
                      _vm._l(_vm.items, function(item, index) {
                        return _c("div", {
                          key: index,
                          staticClass: "similar-product__item",
                          domProps: { innerHTML: _vm._s(item) }
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (_vm.isMobile && _vm.items.length > 1) ||
                              (_vm.isDesktop && _vm.items.length > 3),
                            expression:
                              "\n                            (isMobile && items.length > 1) ||\n                            (isDesktop && items.length > 3)\n                        "
                          }
                        ],
                        ref: "arrows",
                        staticClass: "cart-crosssell__switcher"
                      },
                      [
                        _c(
                          "button",
                          {
                            ref: "arrowLeft",
                            staticClass: "btn btn--clear",
                            attrs: {
                              "aria-label": "Poprzedni produkt",
                              title: "Poprzedni produkt"
                            }
                          },
                          [
                            _c("span", {
                              staticStyle: { width: "17px", height: "32px" },
                              attrs: { "data-icon": "arrow-left" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            ref: "arrowRight",
                            staticClass: "btn btn--clear",
                            attrs: {
                              "aria-label": "Kolejny produkt",
                              title: "Kolejny produkt"
                            }
                          },
                          [
                            _c("span", {
                              staticStyle: { width: "17px", height: "32px" },
                              attrs: { "data-icon": "arrow-right" }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }