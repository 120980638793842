<template>
    <!-- eslint-disable vue/no-v-html -->
    <transition
        name="custom-modal-fade"
        @before-enter="emitEvent('before-enter')"
        @after-leave="emitEvent('after-leave')"
        @leave="emitEvent('leave')"
        @enter="emitEvent('enter')"
    >
        <div
            v-if="modalVisible"
            class="custom-modal-backdrop"
            @click="CloseFromBackdrop"
        >
            <div
                ref="modal"
                class="custom-modal"
                role="dialog"
                tabindex="0"
                v-bind="$attrs"
                :aria-labelledby="
                    $attrs['aria-labelledby'] || modalData.title
                        ? false
                        : 'modalTitle'
                "
                :aria-label="
                    $attrs['aria-labelledby'] || modalData.title || false
                "
                aria-describedby="modalDescription"
                :class="[
                    size ? `custom-modal--${size}` : '',
                    theme ? `custom-modal--${theme}` : ''
                ]"
            >
                <button
                    ref="close"
                    class="custom-modal-close"
                    aria-label="Close"
                    @click.prevent="CloseModal"
                >
                    <span
                        data-icon="close"
                        data-invert
                        style="width: 22px; height: 22px"
                    />
                </button>
                <div class="custom-modal-wrapper">
                    <section
                        v-if="modalData && modalData.title"
                        class="custom-modal-header"
                    >
                        <h4
                            id="modalTitle"
                            class="custom-modal-title"
                            v-html="modalData.title"
                        />
                    </section>

                    <section
                        v-if="modalData.content || $slots.content"
                        id="modalDescription"
                        class="custom-modal-content"
                    >
                        <slot v-if="$slots.content" name="content" />
                        <div
                            v-if="modalData.content"
                            v-html="modalData.content"
                        />
                    </section>

                    <section
                        v-if="
                            modalData &&
                            modalData.buttons &&
                            modalData.buttons.length
                        "
                        ref="actions"
                        class="custom-modal-actions"
                        :data-length="modalData.buttons.length"
                    >
                        <button
                            v-for="(button, index) in modalData.buttons"
                            :key="index"
                            class="button"
                            :class="{ 'btn--bg': button.primary }"
                            @click.prevent="handleButtonAction(button.action)"
                        >
                            {{ button.title }}
                        </button>
                    </section>

                    <section
                        v-if="modalData.footer || $slots.footer"
                        class="custom-modal-footer"
                    >
                        <slot v-if="$slots.footer" name="footer" />
                        <div
                            v-if="modalData.footer"
                            v-html="modalData.footer"
                        />
                    </section>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import icons from '@js/app/icons.js'

export default {
    scopeName: 'modal',
    inheritAttrs: false,
    props: {
        size: {
            type: String,
            required: false,
            default: ''
        },
        theme: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            modalVisible: false,
            modalData: {
                title: '',
                content: '',
                buttons: []
            },
            closeFromBackdropActive: false
        }
    },
    methods: {
        OpenModal(data) {
            this.modalVisible = true
            document.body.classList.add('modal-open')
            if (this.$refs.modal) {
                disableBodyScroll(this.$refs.modal)
            }

            this.$nextTick(() => {
                this.setContent(data)
                icons(this.$el)
            })
        },
        SetCloseFromBackdropActive(val) {
            this.closeFromBackdropActive = val
        },
        CloseFromBackdrop() {
            if (!this.closeFromBackdropActive) {
                return
            }

            this.CloseModal()
        },
        CloseModal() {
            this.modalContent = null
            this.modalVisible = false
            document.body.classList.remove('modal-open')
            if (this.$refs.modal) {
                enableBodyScroll(this.$refs.modal)
            }

            this.$nextTick(() => {
                this.setContent(null)
            })
        },
        setContent(data) {
            if (!data) {
                this.modalData = {}
                return
            }
            this.modalData = data

            if (data.slots != undefined) {
                this.$slots = {}
            }

            setTimeout(() => {
                this.$refs.modal.focus()
            }, 100)
        },
        handleButtonAction(action) {
            if (action) {
                action()
            }
            this.CloseModal()
        },
        emitEvent(event) {
            this.$emit(event)
        }
    }
}
</script>

<style lang="scss">
$mobile: '#{$media-xs}, #{$screen} and (max-width: #{$screen-sm-max}) and (orientation: landscape)';

.custom-modal {
    $ctx: &;
    background-color: #fff;
    width: 100%;
    min-width: 200px;
    max-width: 650px;
    position: relative;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
    text-align: left;
    border-radius: 12px;
    display: block;
    margin: auto;
    transition: all 150ms ease-in-out, max-width 0ms ease-in-out;
    will-change: max-width, max-height, width, height;
    backface-visibility: hidden;

    &:focus {
        outline: 0;
    }

    @media #{$mobile} {
        max-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        overflow: auto;
        top: 0;
        border-radius: 0;
    }

    &--wide {
        max-width: 1338px !important;
        margin-left: 25px;
        margin-right: 25px;

        @media #{$mobile} {
            margin: 0;
        }
    }

    &--short {
        max-width: 400px;
        @media #{$mobile} {
            max-width: 100%;
            overflow: initial;
            min-height: 300px;
            max-width: calc(100vw - 60px);
        }

        .custom-modal-close {
            top: -25px;
            right: -25px;
            position: absolute;
        }
    }

    &-wrapper {
        border-radius: inherit;
        width: 100%;
    }

    section {
        margin-top: 45px;
        margin-bottom: 65px;
        padding-left: 25px;
        padding-right: 25px;

        &.custom-modal-footer {
            margin: 0;
        }

        &.custom-modal-actions {
            margin: 0 20px 15px 20px;
        }

        &.custom-modal-footer {
            padding: 0;
            margin-top: 25px;
        }
    }

    &-close {
        position: absolute;
        width: 55px;
        height: 55px;
        right: -25px;
        top: -25px;
        z-index: $zindex-modal + 1;
        min-width: 0;
        background-color: $sherpa-blue;
        border-radius: 50%;
        border: 0;

        @media #{$mobile} {
            width: 45px;
            height: 45px;
            min-height: 45px;
            top: 10px;
            right: 10px;
        }

        i {
            font-size: 22px;
            color: #fff;
        }
    }

    &-title {
        font-size: 26px;
        text-transform: none;
        text-align: center;
        color: $blue-dianne;

        @media #{$mobile} {
            margin-top: 75px;
        }
    }

    &-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999999;
        @media #{$media-md-up} {
            padding-top: 25px;
            padding-bottom: 15px;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;

        &[data-length='1'] {
            justify-content: center;
        }

        button {
            text-transform: unset;
        }

        @media #{$mobile} {
            flex-direction: column;
            button + button {
                margin-top: 10px;
            }
        }
    }

    button:focus {
        outline: 1px auto Highlight; // FF support
        outline: 1px auto -webkit-focus-ring-color; // Chrome & Safari
    }
}

.custom-modal-fade-enter,
.custom-modal-fade-leave-active {
    opacity: 0;
}

.custom-modal-fade-enter-active,
.custom-modal-fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}
</style>
