import { getSkinUrl } from '../utils/utils'

export function icons(icons) {
    let ICONS_ELEMENTS = []
    if (Array.isArray(icons)) {
        ICONS_ELEMENTS = icons
    } else {
        if (icons.length === 0) {
            ICONS_ELEMENTS = []
            return
        }
        ICONS_ELEMENTS = Array.from(icons.querySelectorAll('[data-icon]'))
    }

    ICONS_ELEMENTS.forEach((iconEl) => {
        const ICON_NAME = iconEl.dataset.icon
        const ICON_URL = getSkinUrl(`icons/${ICON_NAME}.svg`)
        let ICON_CLASSES = ['icon', `icon-${ICON_NAME}`, 'icon--dynamic']

        if (iconEl.hasAttribute('data-invert')) {
            ICON_CLASSES.push('icon--invert')
        }

        if (iconEl.hasAttribute('data-inline')) {
            iconEl.innerHTML = `
            <object data="${ICON_URL}"
            type="image/svg+xml"
            class="${ICON_CLASSES.join(' ')}"
            width="${iconEl.style.width}"
            height="${iconEl.style.height}"></object>
          `
        } else {
            iconEl.innerHTML = `
            <img src="${ICON_URL}"
            class="${ICON_CLASSES.join(' ')}"
            width="${iconEl.style.width}"
            height="${iconEl.style.height}" />
        `
        }
    })
}

export default icons
