var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "custom-modal-fade" },
      on: {
        "before-enter": function($event) {
          return _vm.emitEvent("before-enter")
        },
        "after-leave": function($event) {
          return _vm.emitEvent("after-leave")
        },
        leave: function($event) {
          return _vm.emitEvent("leave")
        },
        enter: function($event) {
          return _vm.emitEvent("enter")
        }
      }
    },
    [
      _vm.modalVisible
        ? _c(
            "div",
            {
              staticClass: "custom-modal-backdrop",
              on: { click: _vm.CloseFromBackdrop }
            },
            [
              _c(
                "div",
                _vm._b(
                  {
                    ref: "modal",
                    staticClass: "custom-modal",
                    class: [
                      _vm.size ? "custom-modal--" + _vm.size : "",
                      _vm.theme ? "custom-modal--" + _vm.theme : ""
                    ],
                    attrs: {
                      role: "dialog",
                      tabindex: "0",
                      "aria-labelledby":
                        _vm.$attrs["aria-labelledby"] || _vm.modalData.title
                          ? false
                          : "modalTitle",
                      "aria-label":
                        _vm.$attrs["aria-labelledby"] ||
                        _vm.modalData.title ||
                        false,
                      "aria-describedby": "modalDescription"
                    }
                  },
                  "div",
                  _vm.$attrs,
                  false
                ),
                [
                  _c(
                    "button",
                    {
                      ref: "close",
                      staticClass: "custom-modal-close",
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.CloseModal.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticStyle: { width: "22px", height: "22px" },
                        attrs: { "data-icon": "close", "data-invert": "" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "custom-modal-wrapper" }, [
                    _vm.modalData && _vm.modalData.title
                      ? _c("section", { staticClass: "custom-modal-header" }, [
                          _c("h4", {
                            staticClass: "custom-modal-title",
                            attrs: { id: "modalTitle" },
                            domProps: { innerHTML: _vm._s(_vm.modalData.title) }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modalData.content || _vm.$slots.content
                      ? _c(
                          "section",
                          {
                            staticClass: "custom-modal-content",
                            attrs: { id: "modalDescription" }
                          },
                          [
                            _vm.$slots.content ? _vm._t("content") : _vm._e(),
                            _vm._v(" "),
                            _vm.modalData.content
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.modalData.content)
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modalData &&
                    _vm.modalData.buttons &&
                    _vm.modalData.buttons.length
                      ? _c(
                          "section",
                          {
                            ref: "actions",
                            staticClass: "custom-modal-actions",
                            attrs: {
                              "data-length": _vm.modalData.buttons.length
                            }
                          },
                          _vm._l(_vm.modalData.buttons, function(
                            button,
                            index
                          ) {
                            return _c(
                              "button",
                              {
                                key: index,
                                staticClass: "button",
                                class: { "btn--bg": button.primary },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleButtonAction(button.action)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(button.title) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modalData.footer || _vm.$slots.footer
                      ? _c(
                          "section",
                          { staticClass: "custom-modal-footer" },
                          [
                            _vm.$slots.footer ? _vm._t("footer") : _vm._e(),
                            _vm._v(" "),
                            _vm.modalData.footer
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.modalData.footer)
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }