<template>
    <div class="cart-similar-product-wrapper">
        <Modal ref="modal" @afterLeave="destroySlider">
            <template #footer>
                <div class="similar-product">
                    <h2 class="similar-product__title">
                        Do Twojego zamówienia możesz dodać
                    </h2>
                    <div class="similar-product__slider">
                        <div ref="items" class="">
                            <div
                                v-for="(item, index) in items"
                                :key="index"
                                class="similar-product__item"
                                v-html="item"
                            ></div>
                        </div>
                        <div
                            v-show="
                                (isMobile && items.length > 1) ||
                                (isDesktop && items.length > 3)
                            "
                            ref="arrows"
                            class="cart-crosssell__switcher"
                        >
                            <button
                                ref="arrowLeft"
                                aria-label="Poprzedni produkt"
                                title="Poprzedni produkt"
                                class="btn btn--clear"
                            >
                                <span
                                    data-icon="arrow-left"
                                    style="width: 17px; height: 32px"
                                />
                            </button>

                            <button
                                ref="arrowRight"
                                aria-label="Kolejny produkt"
                                title="Kolejny produkt"
                                class="btn btn--clear"
                            >
                                <span
                                    data-icon="arrow-right"
                                    style="width: 17px; height: 32px"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../partials/Modal.vue'
import axios from 'axios'
import handleSliderArrows from '@js/utils/handleSliderArrows.js'
import resizeObserver from '../utils/resizeObserver'
import icons from '@js/app/icons.js'
import { getTooltipDataFromCms } from '../../js/utils/getCmsBlocks'

export default {
    components: {
        Modal
    },
    mixins: [resizeObserver],
    scopeName: 'similarproduct',
    data() {
        return {
            items: [],
            slider: null
        }
    },
    mounted() {
        Array.from(document.querySelectorAll('.notify-link')).forEach(
            (notifyLink) => {
                notifyLink.addEventListener(
                    'click',
                    (event) => {
                        event.preventDefault()
                        this.getSimilarProductData(
                            event.currentTarget.getAttribute('data-url')
                        ).then(() => {
                            this.$refs.modal.OpenModal()
                            this.$nextTick(() => {
                                if (this?.items?.length > 1) {
                                    this.initSlider()
                                    icons(this.$refs.arrows)
                                }
                            })
                            this.updateCmsBlocks()
                        })
                    },
                    false
                )
            }
        )
    },
    methods: {
        getSimilarProductData(url) {
            return axios.get(url).then((response) => {
                this.items = Object.values(response.data)
            })
        },
        updateCmsBlocks() {
            this.$nextTick(() => {
                Array.from(
                    document
                        .querySelector('.similar-product__slider')
                        .querySelectorAll('[data-tooltip-cms]')
                ).forEach((element) => {
                    if (element.Tooltip) {
                        element.Tooltip.dispose()
                    }
                })

                getTooltipDataFromCms(
                    Array.from(
                        document
                            .querySelector('.similar-product__slider')
                            .querySelectorAll('[data-tooltip-cms]')
                    )
                )
            })
        },
        initSlider() {
            this.slider = new window.Glider(this.$refs.items, {
                slidesToShow: 1,
                slidesToScroll: 1,
                scrollLock: true,
                rewind: true,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ],
                arrows: {
                    prev: this.$refs.arrowLeft,
                    next: this.$refs.arrowRight
                }
            })
            handleSliderArrows(this.slider)
        },
        destroySlider() {
            if (this.slider) {
                this.slider.destroy()
            }
        }
    }
}
</script>
<style lang="scss">
body:has(.cart-similar-product-wrapper) {
    .tooltip {
        &.show {
            z-index: 999999999;
        }
    }
}
.cart-similar-product-wrapper {
    .custom-modal {
        max-width: 780px;
        @media #{$media-xs} {
            max-width: 330px;
            position: relative;
            overflow: visible;
        }
    }

    .custom-modal-close {
        @media #{$screen} and (max-width: 359px) {
            top: 5px !important;
            right: 5px !important;
        }
        @media #{$media-to-sm} {
            top: -15px;
            right: -15px;
            padding: 4px;
            min-width: 32px;
            min-height: 32px;
            width: 32px !important;
            height: 32px !important;
            z-index: 1000;
            position: absolute;
        }
    }

    .similar-product {
        margin-top: -25px;
        margin-bottom: -25px;
        background-color: #f4f4f4;
        border-radius: 12px;

        &__title {
            font-size: 17px;
            padding-top: 25px;
            padding-bottom: 20px;
            font-weight: 600;
            text-align: center;
            text-transform: none;

            @media #{$screen} and (max-width: 359px) {
                padding-top: 45px;
            }
        }

        &__slider {
            padding: 0 23px;
            padding-bottom: 30px;
        }

        &__item {
            .prod-box {
                border-radius: 24px;
                overflow: hidden;
                padding: 5px 5px 20px;
                .tooltip-max-price-info {
                    margin: 16px 0 0;
                    padding: 0 10px;
                    position: relative;
                    z-index: 2;
                    p {
                        font-size: 11px;
                    }
                }

                &__title {
                    height: 65px;
                    padding: 0 9px;
                }

                &__img {
                    border-bottom: none;
                    min-height: 185px;
                    padding-top: 48px;
                }
            }
        }
    }
}
</style>
